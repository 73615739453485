@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/opensans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/opensans-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/opensans-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/opensans-semibolditalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Lobster';
  src: url('../../fonts/lobster.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Amatic SC';
  src: url('../../fonts/amatic-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Amatic SC';
  src: url('../../fonts/amatic-bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../fonts/comfortaa-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../fonts/comfortaa-bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}


:root {
  --c-primary-dark: #e06150;
  --c-primary: #F87361;
  --c-primary-light: #FF8B7B;
  --c-primary-lightest: rgb(255, 232, 230);

  --c-success: #45C491;
  --c-success-light: #73D3AD;

  --c-highlight: #FDE74C;
  --c-redtext: #FF0000;

  --c-marker-background-teacher: #FCDEDA;

  /* --c-answer-default: #4769E6; */
  --c-answer-default: white;
  --c-answer-correct: #17C264;
  /* 51E091 */
  --c-answer-wrong: #E6270E; 
  /*  */

  --doc-width: 880px;
}

body, input, button, textarea {
  /* font-family: 'Roboto'; */
  font-family: 'Open Sans', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  /* font-family: 'Nunito', sans-serif; */
  /* font-family: 'Work Sans', sans-serif; */
  font-size: 16px;
}

body {
  overflow-x: hidden;
}

body p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

a {
  color: var(--c-primary);
}

a:hover {
  color: var(--c-primary-light);
}

.bp3-menu,
.bp3-popover,
.bp3-popover .bp3-popover-content {
  border-radius: 6px;
  /* overflow: hidden; */
}

.bp3-menu-item {
  border-radius: 6px;
  padding: 6px 12px;
  line-height: 20px;
}
/* Buttons */
/* .bp3-button :-moz-focusring {
  outline: 0 !important;
} */

button:focus {
  outline: 0 !important;
}

.bp3-button,
.bp3-tag.bp3-large {
  border-radius: 6px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 100ms ease-out;
}

.bp3-button:not(.bp3-minimal):not([class*="bp3-intent-"]) {
  background: white;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.bp3-button:not(.bp3-minimal):not([class*="bp3-intent-"]):hover {
  /* opacity: .6; */
  background: white;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .08);
}

.bp3-button.bp3-intent-primary:not(.bp3-minimal) {
  background-color: var(--c-primary);
  background-image: none;
  box-shadow: none;
}

.bp3-button.bp3-intent-primary:hover {
  background-color: var(--c-primary-light);
  box-shadow: none;
}

.bp3-button.bp3-intent-primary.bp3-minimal {
  color: var(--c-primary);
}

.bp3-button.bp3-intent-primary.bp3-minimal:hover {
  color: var(--c-primary-dark);
  background-color: var(--c-primary-lightest);
}

.bp3-button.bp3-intent-success {
  background-color: var(--c-success);
  background-image: none;
  box-shadow: none;
}

.bp3-button.bp3-intent-success:hover {
  background-color: var(--c-success-light);
  box-shadow: none;
}

.bp3-button.bp3-intent-primary:disabled,
.bp3-button.bp3-intent-primary.bp3-disabled {
  background-color: var(--c-primary-light);
}

.bp3-button svg {
  width: 18px;
  height: 18px;
}

/* Navbar */
.bp3-navbar {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  /* background-color: var(--c-primary-lightest); */
  box-shadow: none;
  z-index: 20;
}

.bp3-navbar .bp3-popover-wrapper {
  line-height: 0;
}

.bp3-breadcrumb:not(.bp3-breadcrumb-current) {
  cursor: pointer;
}

.bp3-breadcrumbs > li::after {
  position: relative;
  top: 1px;
}

.bp3-breadcrumb .bp3-editable-text {
  color: #182026;
  font-weight: 600;
}


/* Documents list */
.document-item a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

.document-item .bp3-card,
.folder-item .bp3-card {
  height: 100%;
  cursor: pointer;
  padding: 10px 12px;
  border-radius: 6px;
}

.document-item:hover .bp3-card,
.folder-item:hover .bp3-card {
  /* box-shadow: 0 0 0 1px rgb(248, 115, 97), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);  */
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
}

.document-item .bp3-popover-wrapper,
.folder-item .bp3-popover-wrapper {
  position: absolute;
  right: 6px;
  top: calc(50%);
  transform: translateY(-50%);
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  transition: all 100ms ease-in;
}

.document-item:hover .bp3-popover-wrapper,
.folder-item:hover .bp3-popover-wrapper {
  opacity: 1;
  pointer-events: all;
}

.card-actions {
  transition: all 100ms ease-in;
}

.card-actions:hover {
  background: var(--c-primary) !important;
}

.document-item .button__delete-document {
  transition: all 100ms ease-out;
  opacity: 0;
}

.document-item:hover .button__delete-document {
  opacity: 1;
}


/* Text editor */

body .marker {
  transform: scale(.6)
}

@media screen and (min-width: 600px) {
  body .marker {
    transform: scale(.8)
  }
}

@media screen and (min-width: 1024px) {
  body .marker {
    transform: scale(1)
  }
}

body .marker p {
  margin: 0;
}

body .marker .highlight {
  background-color: var(--c-highlight);
  padding-bottom: 2px;
  border-radius: 6px;
}

/* body .medium-editor-element {
  min-height: 0;
} */

body .medium-editor-element p {
  margin: 0;
}

/* body .medium-editor-element p:last-child {
  margin-bottom: 0;
} */

body .medium-editor-element h2, 
body .medium-editor-element h3 {
  margin-top: 20px;
}

body .medium-editor-element h2:first-child, 
body .medium-editor-element h3:first-child {
  margin-top: 10px;
}

body .medium-editor-element .redtext,
body .quill span.redtext {
  color: var(--c-redtext)
}

body .quill .ql-bubble.ql-container {
  font-family: 'Open Sans', sans-serif;
}

body .quill .ql-bubble .ql-editor {
  overflow: hidden;
  padding: 0;
}

body .quill .ql-bubble .ql-tooltip {
  /* width: auto !important;
  max-width: none !important; */
  /* width: 192px !important; */
  background-color: var(--c-primary-dark);
  margin-top: -78px;
}

body .quill .ql-bubble .ql-toolbar .ql-stroke {
  stroke: rgba(255, 255, 255, .9);
}

body .quill .ql-bubble .ql-toolbar .ql-fill {
  fill: rgba(255, 255, 255, .9);
}

body .quill .ql-bubble .ql-tooltip .ql-tooltip-arrow {
  top: 36px;
  border-bottom: none;
  border-top: 6px solid var(--c-primary-dark);
}

body .quill .ql-bubble .ql-tooltip .ql-formats {
  display: flex;
  margin: 6px;
}

body .quill .ql-bubble .ql-clipboard {
  position: fixed;
}

body .medium-editor-element:focus {
  outline: none;
}

body .medium-editor-toolbar {
  background-color: var(--c-primary);
  margin-top: -10px;
  border: 1px solid var(--c-primary-dark);
}

body .medium-editor-toolbar li button {
  border-right: 1px solid var(--c-primary-dark);
  height: 30px;
  min-width: 30px;
  padding: 6px;
}

body .medium-editor-toolbar li button:hover {
  background-color: var(--c-primary-dark);
}

body .medium-editor-toolbar li .medium-editor-button-active {
  background-color: var(--c-primary-dark);
}

body .static-toolbar {
  position: fixed;
  top: 19px;
  left: 50%;
  transform: translateX(-50%);
}

body .medium-toolbar-arrow-under::after {
  border-color: var(--c-primary-dark) transparent transparent transparent;
  top: 30px;
}

/* Text editor Quill */

.text-editor .quill {
  width: 100%;
}

.text-editor .ql-container {
  font-family: 'Open Sans', sans-serif;
}

.text-editor .ql-editor {
  font-size: 16px;
  line-height: 1.75;
}

.text-editor .ql-container .ql-editor h2 {
  font-size: 1.75em;
}

.text-editor.locked .ql-toolbar.ql-snow {
  opacity: 0;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.text-editor .ql-toolbar.ql-snow {
  position: sticky;
  top: 50px;
  border-radius: 6px 6px 0 0;
  border: none;
  border-bottom: 1px solid #EEE;
  background: white;
  height: 42px;
  z-index: 10;
  transition: all 100ms ease-in;
}

.text-editor .ql-container.ql-snow  {
  height: auto;
  border: none
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='opensans']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='opensans']::before {
  content: 'Open Sans';
  font-family: 'Open Sans';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='comfortaa']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='comfortaa']::before {
  content: 'Comfortaa';
  font-family: 'Comfortaa';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='lobster']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='lobster']::before {
  content: 'Lobster';
  font-family: 'Lobster';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='amatic']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='amatic']::before {
  content: 'Amatic';
  font-family: 'Amatic SC';
}

.ql-font-opensans {
  font-family: 'Open Sans';
}

.ql-font-comfortaa {
  font-family: 'Comfortaa';
}

.ql-font-lobster {
  font-family: 'Lobster';
}

.ql-font-amatic {
  font-family: 'Amatic SC';
}

/* Text file */

.text-editor .bp3-button {
  display: none;
}

@media screen and (min-width: 960px) {
  .text-editor .bp3-button {
    display: inline-flex;
  }
}

.file-wrapper:hover .text-editor.locked .text-editor__edit-button {
  opacity: .6 !important;
}

/* Tools Menu */

.toolbar {
  display: none;
}

@media screen and (min-width: 960px) {
  .toolbar {
    display: block;
  }
}

.tools-menu {
  min-width: 0;
  border-radius: 6px;
}

.tools-menu li + li {
  margin-top: 6px;
}

.tools-menu .bp3-menu-item {
  padding: 8px;
  line-height: 19px;
  border-radius: 4px;
}

.tools-menu:hover .bp3-menu-item-label  {
  padding-left: 2rem !important;
}

.tools-menu .bp3-menu-item-label {
  display: flex;
  align-items: center;
}

.tools-menu .bp3-menu-item > svg {
  margin-right: 0;
  /* margin-bottom: 2px; */
}

/* .tools-menu svg + div {
  position: relative;
  top: -1px;
} */

.tools-menu svg ~ * {
  opacity: 0;
  width: 0;
  padding-left: 0;
  margin-right: 0;
  overflow: hidden;
  transition: all 100ms ease-out;
}

.tools-menu:hover svg ~ * {
  opacity: 1;
  width: auto;
  padding-left: 8px;
}

.tools-menu:hover .bp3-menu-item svg ~ * {
  opacity: 1;
  /* width: 100%; */
  padding-left: 8px;
}

.highlights .ghost-highlight {
  opacity: .3;
}

.highlights .highlight:hover .delete {
  opacity: 1 !important;
}

/* .textInputs .ghost-textInput {
  opacity: .3;
} */

.textInputs .textInput:hover {
  z-index: 10;
}

/* .textInputs .textInput .drawer:before{
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 10px;
  background: rgba(0, 0, 0, .4);
  transform: translateY(-50%);
} */

.textInputs .textInput:hover .drawer,
.textInputs .textInput .drawer.open {
  opacity: 1 !important;
}

.textInputs .textInput .drawer:hover {
  /* background-color: rgb(190, 190, 190) !important; */
}

.textInputs .textInput .drawer:hover svg {
  /* opacity: .8 !important; */
}

.textInputs .textInput .drawer .bp3-popover-wrapper {
  height: 16px;
}

.textInputs .textInput > .bp3-popover-wrapper {
  display: block;
  width: 100%;
}

.textInputs .textInput > .bp3-popover-wrapper .bp3-popover-target {
  display: block;
  width: 100%;
}

.textInput .quill {
  height: 96%;
  cursor: text;
}

.textInput .ql-editor {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file:hover .fileButtons:not(.locked) {
  opacity: .6 !important;
}


/* Draggable stuff */
.react-draggable:hover {
  z-index: 20 !important;
}

.react-draggable-dragging {
  z-index: 20;
}


/* Flip icons */
.fa-align-right,
.fa-align-left,
.fa-align-center {
  transform: rotateX(180deg);
  top: -1px;
  position: relative;
}

/* Table file browser */
.bp3-running-text table tbody tr:first-child th,
table.bp3-html-table tbody tr:first-child th,
.bp3-running-text table tbody tr:first-child td,
table.bp3-html-table tbody tr:first-child td {
  box-shadow: none;
}


/* Dialog */
.bp3-dialog {
  position: relative;
}

.bp3-dialog .bp3-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .1);
  top: 0;
  left: 0;
  border-radius: 5px;
}

/* header */
.btn--lock .bp3-button-text {
  opacity: 0;
  width: 0;
  max-width: 0;
  overflow: hidden;
  transition: all 100ms ease-in;
}

.btn--lock:hover .bp3-button-text {
  opacity: 1;
  width: auto;
  max-width: 100px;
}

.openHelpButton {
  cursor: pointer;
}